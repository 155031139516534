import { defineStore } from "pinia";
import type {
  IExternalService,
  IExternalServiceState,
  IExternalServiceGetters,
  IExternalServiceActions,
} from "~/store/externalService.type";

export const useExternalService = defineStore<
  string,
  IExternalServiceState,
  IExternalServiceGetters,
  IExternalServiceActions
>("externalService", {
  state: () => ({
    services: [],
  }),

  getters: {
    hasServices: (state) => !!state.services.length,
  },

  actions: {
    async fetchData() {
      const config = useRuntimeConfig();

      let code = 500;

      this.services = await $fetch<IExternalService[]>(
        config.public.bdGateway,
        {
          timeout: 5000,
          ignoreResponseError: true,
          onResponse({ response }) {
            code = response.status;
          },
        },
      );

      await $fetch(`/get/api/statistics`, {
        query: {
          service: "REQUEST",
          code,
        },
      });
    },

    getService(serviceId) {
      return (
        this.services.find((service) => serviceId === service.type) ?? null
      );
    },
  },
});
